const actions = {
  setPostsLatest({ commit }, posts) {
    commit('SET_POSTS_LATEST', posts)
  },
  setIsBlogPage({ commit }, posts) {
    commit('SET_IS_BLOG_PAGE', posts)
  },
  setListPost({ commit }, posts) {
    commit('SET_LIST_POST', posts)
  },
  setListTechnologyPost({ commit }, posts) {
    commit('SET_LIST_TECHNOLOGY_POST', posts)
  },
  setListTimebirdPost({ commit }, posts) {
    commit('SET_LIST_TIMEBIRD_POST', posts)
  },
  setListProductPost({ commit }, posts) {
    commit('SET_LIST_PRODUCT_POST', posts)
  },
  setListJob({ commit }, posts) {
    commit('SET_LIST_JOB', posts)
  },
  setListPageBySlug({ commit }, posts) {
    commit('SET_LIST_PAGE_BY_SLUG', posts)
  }
}

export default { actions }
