import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import PageNotFound from '@/views/404'
// Landing Page
import LandingPageLayout from '@/views/layout/LandingPageLayout'
import WorkLayout from '@/views/layout/WorkLayout'
import Team from '@/views/landing/Team'
import Home from '@/views/landing/Home'
import Works from '@/views/landing/Works'
import WorkDetail from '@/views/landing/WorkDetail'
import Terms from '@/views/landing/Terms'
import Terms2048 from '@/views/landing/Terms2048'
import ExchangeTerm from '@/views/landing/ExchangeTerm'
import TermsApps from '@/views/landing/TermsApps'
import Privacy from '@/views/landing/Privacy'
import PostLayout from '@/views/layout/PostLayout'
import Posts from '@/views/landing/Posts'
import PostDetail from '@/views/landing/PostDetail'
import Scan from '@/views/landing/Scan'
import DashboardLayout from "../views/layout/DashboardLayout";
import AuthLayout from "../views/layout/AuthLayout";

const routes = [
  {
    path: '/',
    component: LandingPageLayout,
    hidden: true,
    redirect: {name: 'Home'},
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: { title: 'Trang chủ' }
      },
      {
        path: 'team',
        component: Team,
        name: 'Team',
        meta: { title: 'Team' }
      },
      {
        path: 'works',
        component: WorkLayout,
        redirect: {name: 'Works'},
        children: [
          {
            path: '',
            name: 'Works',
            component: Works,
            meta: { title: 'Việc Làm' }
          },
          {
            path: 'detail/:slug?',
            name: 'WorkDetail',
            component: WorkDetail,
            meta: { title: 'Work Detail' }
          }
        ]
      },
      {
        path: 'posts',
        name: 'PostsLayout',
        component: PostLayout,
        redirect: {name: 'Posts'},
        children: [
          {
            path: '',
            name: 'Posts',
            component: Posts,
            meta: { title: 'Tin tức'}
          },
          {
            path: 'technology-posts',
            name: 'Technology Posts',
            component: Posts,
            meta: { title: 'Kiến Thức Công Nghệ'}
          },
          {
            path: 'timebird-posts',
            name: 'Timebird Posts',
            component: Posts,
            meta: { title: 'Góc TimeBird'}
          },
          {
            path: 'product-posts',
            name: 'Product Posts',
            component: Posts,
            meta: { title: 'Chuyên Tin Sản Phẩm'}
          },
          {
            path: 'detail/:slug?',
            name: 'PostDetail',
            component: PostDetail,
            meta: {title: 'Post Detail'}
          }
        ]
      }
    ]
  },
  {
    path: '/scan',
    component: LandingPageLayout,
    hidden: true,
    name: 'ScanMobile',
    redirect: { name: 'Scan'},
    meta: { requiresAuth: false, title: 'Scan Page'},
    children: [
      {
        path: '',
        component: Scan,
        name: 'Scan',
        meta: { requiresAuth: false, title: 'Scan Page'}
      },
      { path: '*', redirect: {name: 'Scan'}, hidden: true, meta: { requiresAuth: false } }
    ]
  },
  {
    path: '/404',
    component: PageNotFound,
    hidden: true,
    name: 'PageNotFound',
    meta: { requiresAuth: false, title: '404 Page'},
  },
  {
    path: '/lucky-spinner/terms-and-policy',
    component: Terms,
    hidden: true,
    name: 'Term-and-Policy',
    meta: { requiresAuth: false, title: 'Term and Policy'},
  },
  {
    path: '/timebird-puzzle/terms-and-policy',
    component: Terms2048,
    hidden: true,
    name: 'Term-and-Policy-2048',
    meta: { requiresAuth: false, title: 'Term and Policy'},
  },
  {
    path: '/timebird-apps/terms-and-policy',
    component: TermsApps,
    hidden: true,
    name: 'Term-and-Policy-Apps',
    meta: { requiresAuth: false, title: 'Term and Policy'},
  },
  {
    path: '/timebird-exchange/terms-and-policy',
    component: ExchangeTerm,
    hidden: true,
    name: 'Term-and-Policy-Apps',
    meta: { requiresAuth: false, title: 'Term and Policy'},
  },
  { path: '*', redirect: '/', hidden: true, meta: { requiresAuth: false } }
]
// Router catch exception
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location)
    .catch(() => console.log('Routing is not changed'))
}
export default new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
})
