<template>
  <div class="posts-widget">
    <div class="widget" v-loading="isLoading">
      <div class="widget-title">Bài viết mới nhất</div>
      <div class="widget-posts" v-if="listPostOfSidebar.length">
        <template v-for="(post, index) in listPostOfSidebar">
          <div class="widget-post" v-if="post.status" :key="index">
            <router-link
                v-if="post.url"
                :to="{name: 'PostDetail', params: {slug: post.slug}}"
                :style="`background-image: url(${post.url})`"
                class="widget-thumbnail"></router-link>
            <router-link
                v-else
                :to="{name: 'PostDetail', params: {slug: post.slug}}"
                :style="`background-image: url(${post.image})`"
                class="widget-thumbnail"></router-link>
            <div class="widget-content">
              <time class="post-date">{{convertTimeStamp(post.createAt)}}</time>
              <h3 class="post-title"><router-link :to="{name: 'PostDetail', params: {slug: post.slug}}" :title="post.title" v-html="post.title"></router-link></h3>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="widget" style="border: none">
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTimeBird.SoftwareCompany%2F&tabs=events&width=300&height=131&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
  </div>
</template>
<script>
import UtilService from '@/utils/UtilService'
export default {
  data() {
    return {
      isLoading: false
    }
  },
  created() {
    this.fetch()
  },
  computed: {
    listPostOfSidebar() {
      return this.$store.getters.listPostOfSidebar
    },
    postsLatest() {
      return this.$store.getters.postsLatest
    },
  },
  methods: {
    fetch() {
      this.getPosts()
    },
    getPosts() {
      this.isLoading = true
      this.$store.dispatch('getPosts', {
        per_page: 4
      }).then(rs => {
        this.isLoading = false
        if (rs) {
          this.$store.dispatch('setPostsLatest', rs)
        }
      })
    },
    convertTimeStamp(time) {
      return UtilService.convertTimeStamp(time, 'LL')
    }
  }
}
</script>