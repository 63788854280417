import Vue from 'vue'
export default new Vue({
  methods: {
    checkRole (user, route) {
      if (!route.meta || !route.meta.requiresAuth ){
        return true
      }
    },
    checkValidateEmail (email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    checkValidatePhone (phone) {
      var re = /^[0][3,7,8,9]\(?\d{2}\)?\d{3}?\d{3}$/
      return re.test(phone)
    },
    convertTime(time, format = 'YYYY-MM-DD') {
      return time ? this.$moment(Date.parse(time)).utc().local().format(format) : ''
    },
    convertTimeStamp(time, format = 'YYYY-MM-DD') {
      return time ? this.$moment(time).utc().local().format(format) : ''
    },
    convertToTimeStamp(time) {
      return new Date(time).getTime()
    }
  }
})
