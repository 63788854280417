import CONFIG from '@/config/Config'
import { Get, Post, Delete } from '@/utils/RequestService'

const postsAPI = CONFIG.POSTS_API
const service = {
  getPosts: () => Get(`${postsAPI}/posts/posts.json`),
  getTechnologyPosts: () => Get(`${postsAPI}/posts/technologyPosts.json`),
  getTimebirdPosts: () => Get(`${postsAPI}/posts/timebirdPosts.json`),
  getProductPosts: () => Get(`${postsAPI}/posts/productPosts.json`),
  getJobs: (data) => Get(`${postsAPI}/jobs/recruitment.json`, data)
}
export default service
