<template>
  <div>
    <template v-if="job.slug">
      <section class="work-head">
        <div class="container">
          <div class="section-inner">
            <h2 v-html="job.title"></h2>
            <div class="job-description">
              <p>
                <img src="/images/icons/email-white.png" alt="Salary">
                <span>{{job.salary}}</span>
              </p>
              <p>
                <img src="/images/icons/calendar-white.png" alt="Date">
                <span>{{job.time}}</span>
              </p>
              <p>
                <a href="https://www.google.com/maps/place/Timebird/@21.029236,105.7850184,19.13z/data=!4m5!3m4!1s0x3135ab7df7fbe5c3:0xdcaaa0820fa0f671!8m2!3d21.0291123!4d105.7855582?shorturl=1">
                  <img src="/images/icons/marker-white.png" alt="Location">
                  <span>{{job.location}}</span>
                </a>
              </p>
              <p><a href="javascript:;" class="btn" @click.prevent="scrollToContactForm">Ứng tuyển ngay <img src="/images/icons/arrow-right.png" alt="Arrow Right"></a></p>
            </div>
          </div>
        </div>
      </section>
      <section class="job-detail">
        <div class="container">
          <div class="job-detail-item">
            <div v-html="job.content"></div>
          </div>
          <p>
            <router-link :to="{name: 'Works'}">
              <el-button type="primary">Công việc khác</el-button>
            </router-link>
          </p>
        </div>
      </section>
    </template>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isLoading: false,
      job: {}
    }
  },
  created() {
    // this.$store.dispatch('getJobs', {
    //   slug: this.$route.params.slug
    // }).then(rs => {
    //   if (rs) {
    //     this.job = rs.data[0]
    //   }
    //   else {
    //     this.$router.push({name: 'Works'})
    //   }
    // })
    // // this.job = jobs.find(job => job.slug === this.$route.params.slug)
    // if (!this.job) {
    //   this.$router.push({name: 'Works'})
    // }
    this.setData()
  },
  computed: {
    getJobBySlug() {
      return this.$store.getters.getJobBySlug
    }
  },
  methods: {
    setData () {
      const params = this.$route.params
      if(this.getJobBySlug && this.getJobBySlug[params.slug]) {
        this.job = this.getJobBySlug[params.slug]
        console.log(this.job);
      } else {
        this.fetch()
      }
     
    },
    fetch() {
      const params = this.$route.params
      if (!params.slug && params.slug !== 0) {
        this.$router.push({name: 'Works'})
      }
      this.getJob({
        per_page: 1,
        slug: params.slug
      })
    },
    async getJob(data) {
      this.isLoading = true
      await this.$store.dispatch('getJobs', data)
      this.isLoading = false
      this.job = this.getJobBySlug[data.slug]
    },
    scrollToContactForm() {
      const el = this.$parent.$refs.contactForm
      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    }
  }
}
</script>
