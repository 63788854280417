/* eslint-disable no-sequences */
import PostsService from '@/api/PostsService'
import Config from '@/config/Config'
const actions = {
  getPosts({ commit }, data) {
    return new Promise(async resolve => {
      const res = await PostsService.getPosts(data)
      let _listPost = []
      let _listPageBySlug = {}
      let _indexPost = 0 
      const _list = res.reverse()
      _list.forEach((item, index) => {
        if (item.image) {
          item.image = `${Config.CMS_END_POINT}${item.image}`
        }
        if(item.status) {
          _listPost.push(item)
          _listPageBySlug[item.slug] = {
            prev: {
              slug: null,
              title: null
            },
            next: {
              slug: null,
              title: null
            }
          }
          if(_indexPost - 1 >= 0 && _listPost[_indexPost - 1].slug) {
            _listPageBySlug[item.slug].next.slug = _listPost[_indexPost - 1].slug
            _listPageBySlug[item.slug].next.title = _listPost[_indexPost - 1].title
          }
          let currentIndex = index
          while( currentIndex  < _list.length - 1) {
            currentIndex++
            if(_list[currentIndex].slug && _list[currentIndex].status) {
              _listPageBySlug[item.slug].prev.slug = _list[currentIndex].slug
              _listPageBySlug[item.slug].prev.title = _list[currentIndex].title
              break
            }
          }
          _indexPost++
        }
      })
      console.log(_listPageBySlug);
      commit('SET_LIST_POST', _listPost)
      commit('SET_LIST_PAGE_BY_SLUG', _listPageBySlug)
      resolve(_listPost, _listPageBySlug)
    })
  },
  getTechnologyPosts({ commit }, data) {
    return new Promise(async resolve => {
      const res = await PostsService.getTechnologyPosts(data)
      let _listPost = []
      const _list = res.reverse()
      _list.forEach((item, index) => {
        if (item.image) {
          item.image = `${Config.CMS_END_POINT}${item.image}`
        }
        if(item.status) {
          _listPost.push(item)
        }
      })
      commit('SET_LIST_TECHNOLOGY_POST', _listPost)
      resolve(_listPost)
    })
  },
  getTimebirdPosts({ commit }, data) {
    return new Promise(async resolve => {
      const res = await PostsService.getTimebirdPosts(data)
      let _listPost = []
      const _list = res.reverse()
      _list.forEach((item, index) => {
        if (item.image) {
          item.image = `${Config.CMS_END_POINT}${item.image}`
        }
        if(item.status) {
          _listPost.push(item)
        }
      })
      commit('SET_LIST_TIMEBIRD_POST', _listPost)
      resolve(_listPost)
    })
  },
  getProductPosts({ commit }, data) {
    return new Promise(async resolve => {
      const res = await PostsService.getProductPosts(data)
      let _listPost = []
      const _list = res.reverse()
      _list.forEach((item, index) => {
        if (item.image) {
          item.image = `${Config.CMS_END_POINT}${item.image}`
        }
        if(item.status) {
          _listPost.push(item)
        }
      })
      commit('SET_LIST_PRODUCT_POST', _listPost)
      resolve(_listPost)
    })
  },
  getJobs({ commit }, data) {
    return new Promise(async resolve => {
      const res = await PostsService.getJobs(data)
      // res.map((item) => {
      //   item.id = item.id + 1
      // })
      let _listJob = []
      res.reverse().forEach((item, index) => {
        if(item.status) {
          _listJob.push(item)
        }
      })
      commit('SET_LIST_JOB', _listJob)
      resolve(_listJob)
    })
  },
}

export default { actions }
