<template>
  <header id="header" class="header">
    <div class="header-overlay" :class="{ overlay: isShowMenu }" @click="closeMenu"></div>
    <div class="container">
      <div class="menu-hamburger" @click="showMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <label for ="menu-hamburger" class="menu-cover"></label>
      <div class="header-left">
        <div class="logo">
          <router-link :to="{name: 'Home'}">
            <img src="/images/logo.svg" alt="Timebird">
          </router-link>
        </div>
      </div>
      <div class="header-right" :class="{ show: isShowMenu }">
        <div class="logo" @click="closeMenu">
          <router-link :to="{name: 'Home'}">
            <img src="/images/logo.svg" alt="Timebird">
          </router-link>
        </div>
        <ul class="primary-menu">
          <li class="menu-item" @click="closeMenu">
            <router-link :to="{name: 'Home'}">Trang chủ</router-link>
          </li>
          <li class="menu-item">
            <a :href="productURL">Sản phẩm</a>
          </li>
          <li class="menu-item" @click="closeMenu">
            <router-link :to="{name: 'Posts'}">Tin tức</router-link>
            <div class="submenu-list">
              <div class="submenu-item" @click="reloadPage">
                <router-link :to="{name: 'Technology Posts'}" class="submenu-link">Kiến Thức Công Nghệ</router-link>
              </div>
              <div class="submenu-item" @click="reloadPage">
                <router-link :to="{name: 'Timebird Posts'}" class="submenu-link">Góc TimeBird</router-link>
              </div>
              <div class="submenu-item" @click="reloadPage">
                <router-link :to="{name: 'Product Posts'}" class="submenu-link">Chuyên Tin Sản Phẩm</router-link>
              </div>
            </div>
          </li>
          <li class="menu-item" @click="closeMenu">
            <router-link :to="{name: 'Team'}">Team</router-link>
          </li>
          <li class="menu-item" @click="closeMenu">
            <router-link :to="{name: 'Works'}">Việc làm</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import Config from '@/config/Config'
export default {
  data() {
    return {
      productURL: Config.PRODUCT_URL,
      isShowMenu: false
    }
  },
  methods: {
    showMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    closeMenu() {
      this.isShowMenu = false
      this.reloadPage()
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>
