<template>
  <div id="works-page">
    <router-view></router-view>
    <section class="work-form" ref="contactForm">
      <div class="container">
        <div class="section-left">
          <form class="submit-form" @submit.prevent="sendEmail" :class="{'loading': isLoading}">
            <div class="field">
              <el-input placeholder="Họ tên *" v-model="formCV.name"></el-input>
            </div>
            <div class="field">
              <el-input placeholder="Email *" type="email" v-model="formCV.email"></el-input>
            </div>
            <div class="field">
              <el-input placeholder="Phone *" type="tel" v-model="formCV.phone"></el-input>
            </div>
            <div class="field">
              <el-input placeholder="LinkCV *" type="url" v-model="formCV.linkcv"></el-input>
            </div>
            <div class="field">
              <el-input placeholder="Vị trí ứng tuyển *" v-model="formCV.position"></el-input>
            </div>
            <div class="field">
              <el-input type="textarea" placeholder="Điều gì khiến bạn muốn vào TimeBird" v-model="formCV.message"></el-input>
            </div>
            <div class="field">
              <button class="el-button el-button--primary">Ứng tuyển</button>
            </div>
          </form>
        </div>
        <div class="section-right flex-1">
          <div class="title">
            <h2>GỬI CV ỨNG TUYỂN</h2>
          </div>
          <img src="/images/work/contact.png" alt="Contact">
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
export default  {
  data() {
    return {
      isLoading: false,
      formCV: {
        name: '',
        email: '',
        phone: '',
        linkcv: '',
        message: '',
        position: ''
      }
    }
  },
  computed: {
    isValidated() {
      return this.formCV.name !== ''
          && this.formCV.email !== ''
          && this.formCV.phone !== ''
          && this.formCV.linkcv !== ''
          && this.formCV.position !== ''
    }
  },
  methods: {
    sendEmail() {
      if (!this.isValidated) {
        this.$notify.error({
          title: 'Thông tin chưa đầy đủ',
          message: 'Vui lòng bổ sung thông tin còn thiếu',
          duration: 2400
        })
        return
      }
      this.isLoading = true
      emailjs.send('service_bcihgps', 'template_vckjs4e', this.formCV, 'user_lBGprrDgAQsKmpsoZj5n5')
        .then((result) => {
          this.isLoading = false
          this.$notify.success({
            title: 'Thành công',
            message: 'Email của bạn đã được gửi thành công',
            duration: 2400
          })
          this.formCV.name = this.formCV.email = this.formCV.phone = this.formCV.linkcv = this.formCV.message = this.formCV.position = ''
        }, (error) => {
          this.isLoading = false
          this.$notify.error({
            title: 'Thất bại',
            message: 'Email chưa được gửi',
            duration: 2400
          })
        })
    }
  }
}
</script>
