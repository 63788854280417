import { keyBy, slice } from "lodash"

const state = {
  isBlogPage: true,
  postsLatest:[],
  listPost: [],
  listTechnologyPost: [],
  listTimebirdPost: [],
  listProductPost: [],
  listJob: [],
  listPageBySlug: {}
}

const getters = {
  isBlogPage: state => state.isBlogPage,
  postsLatest: state => state.postsLatest,
  listPageBySlug: state => state.listPageBySlug,
  listPost: (state) => state.listPost,
  listTechnologyPost: (state) => state.listTechnologyPost,
  listTimebirdPost: (state) => state.listTimebirdPost,
  listProductPost: (state) => state.listProductPost,
  listJob: state => state.listJob,
  listPostOfSidebar: (state) => {
    return slice(state.listPost, 0, 3)
  },
  listJobOfHome: (state) => {
    return slice(state.listJob, 0, 3)
  },
  getPostBySlug: (state) => {
    return keyBy(state.listPost, "slug")
  },
  getJobBySlug: (state) => {
    return keyBy(state.listJob, "slug")
  }
}

const mutations = {
  SET_IS_BLOG_PAGE: (state, data) => {
    state.isBlogPage = data
  },
  SET_POSTS_LATEST: (state, data) => {
    state.postsLatest = data
  },
  SET_LIST_POST: (state, data) => {
    state.listPost = data
  },
  SET_LIST_TECHNOLOGY_POST: (state, data) => {
    state.listTechnologyPost = data
  },
  SET_LIST_TIMEBIRD_POST: (state, data) => {
    state.listTimebirdPost = data
  },
  SET_LIST_PRODUCT_POST: (state, data) => {
    state.listProductPost = data
  },
  SET_LIST_JOB: (state, data) => {
    state.listJob = data
  },
  SET_LIST_PAGE_BY_SLUG: (state, data) => {
    state.listPageBySlug = data
  }
}

export default { state, getters, mutations }
