import Vue from 'vue'
import Vuex from 'vuex'
import postModule from '@/store/modules/posts/index'
Vue.use(Vuex)


const store = new Vuex.Store({
  modules: {
    postModule
  }
})

export default store
