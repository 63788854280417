<template>
  <div id="works-page" class="team-page">
    <section class="work-head"></section>
    <section class="work-intro">
      <div class="container">
        <div class="section-inner">
          <div class="section-left flex-2">
            <div class="title">
              <h2>team work <br/>TIMEBIRD</h2>
            </div>
          </div>
          <div class="section-right flex-3">
            <p>Làm việc theo Agile Scrum, chúng tôi tổ chức Công ty theo các đội nhóm linh hoạt. Mỗi thành viên luôn sẵn sàng tham gia vào một vài dự án tùy theo công nghệ và năng lực. Và bằng cách tự đăng ký task, các thành viên có cơ hội ngang bằng trong lựa chọn hướng phát triển để hoàn thiện bản thân.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="all-team" sticky-container>
      <div class="container">
        <div class="section-left">
          <div class="team-menu" v-sticky sticky-offset="{top: 100, bottom: 100}" sticky-side="both">
            <div class="menu-item" v-for="(team, index) in allTeam" :key="index">
              <a href="javascript:;" @click="active = team.id" :class="{'active': active === team.id}">{{team.title}}</a>
            </div>
          </div>
        </div>
        <div class="section-right">
          <div class="team" v-for="(team, index) in allTeam" :key="index">
            <template v-if="active === team.id">
              <div class="team-img">
                <img :src="team.img" :alt="team.title">
              </div>
              <div class="team-title">
                <div class="title">
                  <h2>{{team.title}}</h2>
                </div>
                <router-link :to="{name: 'Works'}" class="btn">Ứng tuyển</router-link>
              </div>
              <div class="team-content" v-html="team.content"></div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Sticky from 'vue-sticky-directive'
export default {
  directives: {Sticky},
  data() {
    return {
      active: 'blockchain',
      allTeam: [
        {
          id: 'blockchain',
          title: 'Blockchain',
          img: '/images/team/blockchain.png',
          content: '<p><strong>Brief :</strong>Tạo ra những sản phẩm bảo mật, minh bạch, loại bỏ trung gian, dữ liệu không thể thay đổi.</p>' +
              '<p><strong>Details :</strong>TimeBird ngoài việc nghiên cứu và ứng dụng Blockchain nhằm tạo ra các sản phẩm mà thị trường đang cần như sàn giao dịch, ví điện tử, các ứng dụng phi tập trung DApp. Blockchain còn được ứng dụng trong chuyển đổi số, giúp tối ưu năng suất và thời gian làm việc, đảm bảo an toàn và minh bạch trong giao dịch.</p>' +
              '<p><strong>Học gì để làm Blockchain :</strong>Bản chất của Blockchain là tổng hợp của các công nghệ nền tảng như Cryptography, Peer to Peer Communication, Database, Server và Programing Language. Ngoài ra bạn cũng cần biết về Smart Contract để phục vụ phát triển các ứng dụng phi tập trung. Nên để có thể tiếp cận dễ dàng với Blockchain thì chúng ta cần có kiến thức của nhiều mảng và đặc biệt phải thành thạo ít nhất một ngôn ngữ lập trình. Bên cạnh đó, là một Blockchain Developer bạn cần luôn luôn tìm hiểu thị trường đang có gì và cần gì để bắt kịp xu thế.</p>'
        },
        {
          id: 'ai',
          title: 'AI',
          img: '/images/team/ai.png',
          content: '<p><strong>Brief :</strong>Tạo ra các sản phẩm trí tuệ nhân tạo nhằm giảm thiểu sức lao động của con người.</p>' +
              '<p><strong>Details :</strong>Thực hiện nghiên cứu và ứng dụng để tạo ra các sản phẩm theo hướng tự động hóa, thay thế con người ở những lĩnh vực khác nhau như xử lý hình ảnh, đọc thông tin, verify người dùng, xử lý giao dịch,.... giúp tiết kiệm thời gian, tăng năng suất và tối ưu hóa sức người trong việc thu thập, xử lý lượng thông tin, dữ liệu lớn.</p>' +
              '<p><strong>Học gì để làm AI :</strong>Để làm chủ công nghệ AI bạn cần phải biết Python, có kiến thức về Khoa học máy tính. Bên cạnh đó, bạn cần có sự thấu hiểu dữ liệu, chịu khó tìm tòi, học hỏi, kiên nhẫn và không ngừng cập nhật, nâng cao kiến thức giúp bản thân phát triển.</p>'
        },
        {
          id: 'web-fullstack',
          title: 'Web Fullstack',
          img: '/images/team/web-fullstack.png',
          content: '<p><strong>Brief :</strong>Sản phẩm đúng và đủ tính năng, hiệu năng ổn định</p>' +
              '<p><strong>Details :</strong>Theo xu thế phát triển tất cả các phần mềm đều được chuyển tải thành các ứng dụng di động để người dùng có thể dễ dàng tiếp cận và thao tác mọi lúc, mọi nơi.</p>' +
              '<p><strong>Học gì để làm Web Fullstack :</strong>Để làm được Web Fullstack bạn cần nắm được các ngôn ngữ lập trình cơ bản như HTML, CSS, Java, Javascript. Bên cạnh đó, bạn cần rèn luyện kỹ năng phân tích, giao tiếp, khả năng xử lý linh hoạt và có thể đọc được tài liệu tiếng Anh.</p>'
        },
        {
          id: 'mobile',
          img: '/images/team/mobile.png',
          title: 'Mobile',
          content: '<p><strong>Brief :</strong>App chạy mượt mà, tốc độ xử lý nhanh</p>' +
              '<p><strong>Details :</strong>Theo xu thế phát triển tất cả các phần mềm đều được chuyển tải thành các ứng dụng di động để người dùng có thể dễ dàng tiếp cận và thao tác mọi lúc, mọi nơi.</p>' +
              '<p><strong>Học gì để làm Mobile :</strong>Các bạn học CNTT, khoa học máy tính đều có thể trở thành lập trình viên Mobile. Bên cạnh đó, để phát triển chuyên về mảng Mobile, bạn cần nên nắm chắc các ngôn ngữ lập trình như Java, Objective-C hoặc Swift. Ngoài ra bạn cũng nên rèn thêm các kỹ năng mềm như phân tích, giao tiếp, khả năng xử lý linh hoạt để giúp bạn phát triển tốt hơn trong ngành này.</p>'
        },
        {
          id: 'business-analyst',
          title: 'Business Analyst',
          img: '/images/team/business-analyst.png',
          content: '<p><strong>Brief:</strong>Định nghĩa chính xác nhu cầu của khách hàng\n</p>' +
              '<p><strong>Details:</strong>Chỉ từ những ý tưởng sơ khai ban đầu của khách hàng, qua khai thác, trao đổi với khách hàng BA có thể xây dựng thành một phần mềm với nhiều tính năng tương tác linh hoạt, và có khả năng đáp ứng được cả nhu cầu tương lai. Nói cách khác BA chính là người định hình ra hình hài sản phẩm. BA cũng đồng thời là người kiểm duyệt sản phẩm cuối cùng trước khi đưa sản phẩm tới bàn giao cho khách hàng. Với những Công ty làm sản phẩm thì BA có thể phát triển lên tầm cao hơn là Product Owner - người định hướng phát triển trọn vẹn vòng đời của một sản phẩm.</p>' +
              '<p><strong>Học gì để làm BA:</strong>Người làm BA đòi hỏi nhiều kỹ năng và kiến thức sâu rộng về sản phẩm, thị trường. Thông thường các bạn học ngành CNTT hoặc khối ngành kinh tế sẽ dễ dàng tiếp cận với công việc BA hơn. Các bạn học ngành CNTT có lợi thế hiểu được các thuật ngữ kỹ thuật nên chỉ cần rèn luyện thêm một số kỹ năng mềm và hiểu biết về thị trường. Còn các bạn khối kinh tế với lợi thế nhạy bén về cơ hội kinh doanh, về nhu cầu của thị trường chỉ cần học thêm các tools sử dụng trong công việc và hiểu về quy trình phát triển phần mềm để có thể giao tiếp thuận lợi với các bạn làm chuyên môn kỹ thuật.\n' +
              'Nếu bạn cần hỗ trợ hay ứng tuyển thực tập, hãy liên lạc với chúng tôi tại địa chỉ email <a href="mailto:recruitment@timebird.org">recruitment@timebird.org</a>.</p>'
        },
        {
          id: 'quality-assurance',
          title: 'Quality Assurance',
          img: '/images/team/quality-assurance.png',
          content: '<p><strong>Brief:</strong>Đảm bảo phần mềm thực hiện đúng và đủ chức năng trước khi bàn giao tới khách hàng</p>' +
              '<p><strong>Deitals :</strong>Với vị trí này có nhiều Công ty gọi là Tester, QC (Quality Control) nhưng tại TimeBird chúng tôi định nghĩa là QA (Quality Asurrance). Nhân viên QA tại TimeBird tùy theo kinh nghiệm và năng lực có thể thực hiện một trong các nghiệp vụ sau:</p>' +
              '<ul>' +
                '<li>Functional QA: Kiểm thử phần mềm đảm bảo sản phẩm hoạt động đúng và đủ luồng nghiệp vụ</li>' +
                '<li>Technical QA: Kiểm thử hiệu năng và độ bảo mật của sản phẩm</li>' +
                '<li>Process QA: Định hình và giám sát quá trình sản xuất tuân thủ đúng theo quy trình đã thống nhất</li>' +
              '</ul>' +
              '<p><strong>Học gì để làm QA :</strong>Đặc thù của QA là những con người tỉ mỉ, cẩn thận và tư duy logic. Các bạn học ngành CNTT, khoa học máy tính hoàn toàn phù hợp để trở thành QA.\n' +
              'Nếu bạn cần hỗ trợ hay ứng tuyển thực tập, hãy liên lạc với chúng tôi tại địa chỉ email <a href="mailto:recruitment@timebird.org">recruitment@timebird.org</a>.</p>'
        },
        {
          id: 'design',
          title: 'Design',
          img: '/images/team/design.png',
          content: '<p><strong>Brief :</strong>Tạo ra những giao diện trực quan và thẩm mỹ cao</p>' +
              '<p><strong>Details :</strong>Màu sắc tinh tế, bố cục trình bày đơn giản, tiện dụng là những tiêu chí TimeBird đặt ra khi bắt đầu thiết kế ứng dụng. Một ứng dụng có nhiều tính năng hữu ích nhưng ngay từ giao diện ban đầu đã khó hiểu, làm cho người dùng mất nhiều thời gian để có thể sử dụng thì ứng dụng đó đã tự làm mất cơ hội phát triển. <br/>' +
              'Các chuyên gia thiết kế TimeBird làm việc trên nhiều nền tảng, chú trọng tới thói quen của người dùng, tỉ mỉ tới từng chi tiết nhằm đem lại trải nghiệm tốt nhất cho người dùng.</p>' +
              '<p><strong>Học gì để làm Designer :</strong>Trong công ty phần mềm vị trí này gọi là UI/UX Designer hoặc Web/App Designer. UI UX là cách gọi tắt của User Interface (giao diện người dùng) và User Experience (trải nghiệm người dùng). Đây là 2 yếu tố rất quan trọng trong việc thiết kế 1 website/ application. Với UI các bạn hoàn toàn có thể tham gia các khóa học về thiết kế đồ họa hoặc truyền thông đa phương tiện tại các trường đại học, trung tâm đào tạo Aptech, ITplus hoặc tại FPT Arena Multimedia. Còn với UX phần lớn các designer có được đều là góp nhặt từ kinh nghiệm làm việc, từ những nghiên cứu chuyên sâu về sản phẩm, thị trường và nhu cầu người dùng. <br/>' +
              'Nếu bạn cần hỗ trợ hay ứng tuyển thực tập, hãy liên lạc với chúng tôi tại địa chỉ email <a href="mailto:recruitment@timebird.org">recruitment@timebird.org</a>.</p>'
        },
        {
          id: 'it',
          title: 'IT',
          img: '/images/team/it.png',
          content: '<p><strong>Brief :</strong>Quản lý toàn bộ hệ thống CNTT của công ty và hỗ trợ nhân sự trong công ty xử lý máy móc, thiết bị.</p>' +
              '<p><strong>Details :</strong>Quản lý tài sản, tài nguyên công nghệ thông tin nội bộ của Công ty hỗ trợ cho các bộ phận được cung cấp đầy đủ tài nguyên, thiết bị trong quá trình làm việc. Bên cạnh đó, IT Helpdesk còn cần nghiên cứu, thiết kế, tối ưu hệ thống hạ tầng (mạng, cơ sở dữ liệu) của Công ty để đảm bảo hệ thống hoạt động ổn định, hiệu quả.</p>' +
              '<p><strong>Học gì để làm IT :</strong>Là người quản lý toàn bộ hệ thống CNTT của công ty, vì thế bạn cần có kiến thức về CNTT và kỹ năng chung về mạng. Ngoài ra, bạn cần có kỹ năng quản lý thời gian, sự nhiệt tình và một chút khả năng giao tiếp để dễ dàng hơn trong việc hỗ trợ các nhân sự trong công ty.</p>'
        },
        {
          id: 'back-office',
          title: 'Back Office',
          img: '/images/team/back-office.png',
          content: '<p><strong>Brief :</strong>Hỗ trợ các thành viên trong công ty và đảm bảo no đủ về mặt vật chất cũng như tinh thần cho anh em.</p>' +
              '<p><strong>Details :</strong>Xây dựng, hướng dẫn, ban hành các nội quy, quy định, chính sách nội bộ để đảm bảo công ty vận hành ổn định. Tổ chức các hoạt động giúp gắn kết các thành viên và các hoạt động training nhằm nâng cao năng lực nhân sự. Bên cạnh đó cũng sẽ phối hợp với bạn giám đốc, xử lý các hành vi sai phạm, gây ảnh hưởng đến công ty và đồng nghiệp.</p>' +
              '<p><strong>Học gì để làm BO :</strong>Các bạn học chuyên ngành tài chính, kế toán, kinh tế, luật sẽ dễ dàng tiếp cận công việc hơn. Tuy nhiên, ngày nay vẫn nhiều bạn trái ngành nhưng kết quả làm việc vẫn rất tốt, nên nếu bạn có học trái ngành nhưng lại yêu thích công việc của khối BO thì đừng ngần ngại. Tuy nhiên đề làm ở khối BO, bạn cần có sự tỉ mỉ, cẩn thận, nhanh nhạy trong tính toán, ghi nhớ tốt, giao tiếp tốt và có sự công tâm trong mọi trường hợp.</p>'
        }
      ]
    }
  }
}
</script>
