<template>
  <section class="section section-footer">
    <div class="section-inner">
      <div class="container">
        <div class="flex-box">
          <div class="section-left flex-1">
            <div class="footer-logo">
              <a href="#">
                <img src="/images/logo-footer.png" alt="Logo">
              </a>
            </div>
            <div class="socials">
              <a href="https://www.facebook.com/TimeBird.SoftwareCompany/" target="_blank"><img src="/images/icons/facebook.png" alt="Facebook"></a>
              <a href="https://www.tiktok.com/@timebirdorg" target="_blank"><img src="/images/icons/tiktok.png" alt="Tiktok"></a>
              <a href="https://www.youtube.com/channel/UCliYpkzGitOPrzlbU5FrD-g" target="_blank"><img src="/images/icons/youtube.png" alt="Youtube"></a>
            </div>
          </div>
          <div class="section-right flex-2">
            <div class="flex-box">
              <ul class="footer-menu">
                <li><router-link :to="{name: 'Home'}">Trang chủ</router-link></li>
                <li><a :href="productURL">Sản phẩm</a></li>
                <li>
                  <router-link :to="{name: 'Posts'}">Tin tức</router-link>
                </li>
                <li><router-link :to="{name: 'Team'}">Team</router-link></li>
                <li><router-link :to="{name: 'Works'}">Việc làm</router-link></li>
              </ul>
            </div>
            <div class="flex-box">
              <div class="items">
                <div class="item">
                  <h2 class="item-title">Liên hệ</h2>
                  <ul>
                    <li>
                      <a href="mailto:contact@timebird.org">
                        <img src="/images/icons/email.svg" alt="Email">
                        <span>contact@timebird.org</span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:+84366739899">
                        <img src="/images/icons/phone.svg" alt="Phone">
                        <span>+84 366 739 899</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://goo.gl/maps/tTM5KXE55q677iU29">
                        <img src="/images/icons/location.svg" alt="Location">
                        <span>Số 3/1/82 Dịch Vọng Hậu, Cầu Giấy, Hà Nội</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="item">
                  <h2 class="item-title">Địa chỉ</h2>
                  <p>
                    <a href="https://goo.gl/maps/tTM5KXE55q677iU29">
                      <img src="/images/map.png" alt="Map">
                    </a>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="copyright">
        <p>© 2020 <router-link :to="{name: 'Home'}">Timebird</router-link>. All Rights Reserved</p>
      </div>
    </div>
  </section>
</template>
<script>
import Config from '@/config/Config'
export default {
  data() {
    return {
      productURL: Config.PRODUCT_URL
    }
  }
}
</script>

