<template>
 <p>Dashboard Layout</p>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {
  },
  computed: {
  },
  methods: {
  }
}
</script>
