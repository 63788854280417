import axios from 'axios'
import LocalStorage from '@/utils/LocalStorage'
import store from '@/store/index'
import CONSTANT from '@/config/Constant'

// create an axios instance
const http = axios.create({
})
export default http

http.interceptors.request.use(
  async config => {
    // Do something before request is sent
    return config
  },
  error => {
    console.log(error)
    // Do something with request error
    Promise.resolve(false)
  }
)
http.interceptors.response.use(
  response => {
    var data = response.data
    if (data && data.status && data.message) {
      if (data.status.toLowerCase() === CONSTANT.SUCCESS.toLowerCase()) {
        store.dispatch('showSuccessMsg', data.message)
      }
      if (data.status.toLowerCase() === CONSTANT.ERROR.toLowerCase()) {
        store.dispatch('showErrorMsg', data.message)
      }

    }
    if(response.headers['x-wp-totalpages']) {
      return response
    }
    return data
  },
  error => {
    // Refresh token
    let rs = error.response
    let message = rs.data && rs.data.message
    if (rs && rs.status === CONSTANT.UNAUTHORIZED_CODE) {
      store.dispatch('logOut')
    } else if (rs && rs.status === CONSTANT.INVALID_TOKEN) {
      store.dispatch('logOut')
    } else if (rs && rs.status === CONSTANT.INVALID_ROLE) {
      store.dispatch('showErrorMsg', 'You have not permission')
    }
    rs.data.message &&
      store.dispatch('showErrorMsg', rs.data.message)
    return Promise.resolve(false)
  }
)
