<template>
  <div>
    <section class="work-head">
      <div class="container">
        <div class="section-inner">
          <h2>Tham gia cùng Timebird</h2>
          <p>Tại TimeBird, chúng tôi tạo ra một môi trường làm việc năng động, sáng tạo giúp tinh thần của những chú chim nhà TimeBird luôn tốt nhất để đạt hiệu quả cao. Bạn có thể thoải mái tham gia các hoạt động như bóng bàn, ca nhạc, .. hoặc trò chuyện, chém gió tại căng tin của công ty trong những lúc giải lao, thư giãn.</p>
          <p>
            <a href="javascript:;" class="btn" @click.prevent="scrollToContactForm">Ứng tuyển ngay <img src="/images/icons/arrow-right.png" alt="Arrow Right"></a>
          </p>
          <a href="javascript:;" class="arrow-work" @click.prevent="scrollToElement"><img src="/images/icons/arrow.png" alt="Arrow"></a>
        </div>
      </div>
    </section>
    <section class="work-benefits" ref="scrollToMe">
      <div class="container">
        <div class="section-inner">
          <div class="title">
            <h2>Quyền lợi của bạn tại TimeBird</h2>
          </div>
          <div class="benefits-items">
            <div class="item">
              <div class="item-icon">
                <img src="/images/work/fireworks.png" alt="Icon">
              </div>
              <div class="item-title">
                <h3>Không chỉ làm việc</h3>
              </div>
              <div class="item-desc">
                <p>Một ngày tại TimeBird sẽ không chỉ là làm việc, bạn sẽ có những giây phút giải lao ở Canteen, khu bóng bàn,… của công ty, hay những trận đấu Game đỉnh cao giữa các tuyển thủ không chuyên.</p>
              </div>
            </div>
            <div class="item">
              <div class="item-icon">
                <img src="/images/work/problem-solving.png" alt="Icon">
              </div>
              <div class="item-title">
                <h3>Không giới hạn cấp bậc</h3>
              </div>
              <div class="item-desc">
                <p>Ở TimeBird, bạn có thể làm việc hoặc nhờ sự giúp đỡ trực tiếp từ ban giám đốc mà không cần thông qua quản lý trực tiếp của mình</p>
              </div>
            </div>
            <div class="item">
              <div class="item-icon">
                <img src="/images/work/surprise.png" alt="Icon">
              </div>
              <div class="item-title">
                <h3>Lương không phải là tất cả</h3>
              </div>
              <div class="item-desc">
                <p>TimeBird luôn ghi nhận công sức và sự cố gắng của bạn, ngoài lương và phụ cấp ăn ca hàng tháng bạn có thể sẽ nhận được thưởng từ những dự án.</p>
              </div>
            </div>
            <div class="item">
              <div class="item-icon">
                <img src="/images/work/support.png" alt="Icon">
              </div>
              <div class="item-title">
                <h3>Giúp đỡ nhau phát triển</h3>
              </div>
              <div class="item-desc">
                <p>Tại TimeBird, bạn có thể đăng ký mục tiêu ngắn hạn và dài hạn của bản thân. Và chúng tôi sẽ giúp đỡ để bạn đạt được mục tiêu đó bằng cách tổ chức các buổi đào tạo, hay chỉa sẻ những cách để đat được mục tiêu một cách nhanh nhất, giúp bạn phát triển sự nghiệp một cách bền vững.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="work-jobs" class="work-jobs" v-if="false">
      <div class="container">
        <div class="section-inner">
          <div class="section-lefte">
            <div class="title">
              <h2>VIỆC LÀM BẠN QUAN TÂM</h2>
            </div>
          </div>
          <div class="section-right">
            <form action="#" @submit.prevent="" class="search-form" v-if="false">
              <div class="search-field flex-1">
                <el-select v-model="form.position" placeholder="Vị trí ứng tuyển" clearable>
                  <el-option :label="value" :value="key" v-for="(value, key) in listPosition" :key="key">{{value}}</el-option>
                </el-select>
              </div>
              <div class="search-field flex-1">
                <el-select v-model="form.level" placeholder="Cấp bậc" clearable>
                  <el-option :label="value" :value="key" v-for="(value, key, index) in listLevel" :key="index">{{ value }}</el-option>
                </el-select>
              </div>
              <div class="search-field">
                <el-button type="primary" plain>Tìm kiếm</el-button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="jobs container" v-loading="isLoading">
        <div class="job" v-for="(job, index) in jobs" :key="index">
          <div class="job-image" :style="{backgroundImage: `url(${job.url? job.url : `https://cms.timebird.org/${job.image}`})`}"></div>
          <div class="job-title">
            <h3><router-link :to="{name: 'WorkDetail', params: {slug: job.slug}}" v-html="job.title"></router-link></h3>
          </div>
          <div class="job-description">
            <p>
              <img src="/images/icons/email.png" alt="Salary">
              <span class="one-line" :title="job.salary">{{job.salary}}</span>
            </p>
            <p>
              <img src="/images/icons/calendar.png" alt="Date">
              <span>{{convertTimeStamp(job.time)}}</span>
            </p>
            <p>
              <a href="https://www.google.com/maps/place/Timebird/@21.029236,105.7850184,19.13z/data=!4m5!3m4!1s0x3135ab7df7fbe5c3:0xdcaaa0820fa0f671!8m2!3d21.0291123!4d105.7855582?shorturl=1">
                <img src="/images/icons/marker.png" alt="Location">
                <span>{{job.location}}</span>
              </a>
            </p>
            <p><router-link class="view-more" :to="{name: 'WorkDetail', params: {slug: job.slug}}"><el-button type="primary">Chi tiết</el-button></router-link></p>
          </div>
        </div>
      </div>
      <div class="container">
        <a href="javascript:;" v-if="isShowLoadMore" class="btn-more-jobs" @click.prevent="loadMore"><img src="/images/icons/arrow-down.png" alt="Arrow"><span>Xem thêm</span></a>
      </div>
    </section>
  </div>
</template>
<script>
import UtilService from '@/utils/UtilService'
export default  {
  data() {
    return {
      jobs: [],
      isLoading: false,
      form : {
        position: '',
        level: ''
      },
      listPosition: {
        backend: 'Back-end',
        frontend: 'Front-end',
        mobile: 'Mobile',
        marketing: 'Marketing/Truyền thông',
        qa: 'Quản lý chất lượng',
        hr: 'Nhân sự',
        ba: 'Business Analyst',
        tester: 'Tester',
        administrative: 'Hành chính, văn phòng',
        designer: 'Thiết kế'
      },
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalPosts: 1
      },
      listLevel: {
        intern: 'Thực tập sinh',
        fresher: 'Fresher',
        employee: 'Nhân viên',
        leader: 'Trưởng nhóm hoặc cao hơn'
      }
    }
  },
  created() {
    // this.loadJobs()
  },
  computed: {
    isShowLoadMore() {
      return this.pagination.currentPage < this.pagination.totalPages
    }
  },
  methods: {
    loadJobs() {
      this.isLoading = true
      this.$store.dispatch('getJobs', {
        per_page: 10,
        page: this.pagination.currentPage
      }).then(rs => {
        this.isLoading = false
        if (rs) {
          this.jobs = rs
          // this.pagination.totalPosts = rs.headers['x-wp-total']
          // this.pagination.totalPages = rs.headers['x-wp-totalpages']
        }
      })
    },
    loadMore() {
      this.pagination.currentPage ++
      this.loadJobs()
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe
      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },
    scrollToContactForm() {
      const el = this.$parent.$refs.contactForm
      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },
    convertTimeStamp(time) {
      return UtilService.convertTimeStamp(time, 'LL')
    },
  }
}
</script>
