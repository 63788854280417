<template>
  <div class="posts-list" v-loading="isLoading">
    <!--  Current Post  -->
    <article class="single-post" :id="`post-${post.id}`" v-if="post">
      <div class="post-content">
        <div class="post-category">
          <template v-for="(item, index) in categoryLink">
            <template v-if="item.id === post.categoryId">
              <router-link :to="{ name: item.link }" class="post-link" :key="index">
                {{ item.title }}
              </router-link>
            </template>
          </template>
        </div>
        <time class="post-date">{{convertTimeStamp(post.createAt)}}</time>
        <h2 class="post-title" v-html="post.title"></h2>
        <div class="post-entry" v-html="post.content">
        </div>
      </div>
    </article>
    <!--  End Current Post  -->
    <!--  Next & Previous Posts  -->
    <div class="navigation post-navigation" v-if="listPageBySlug">
      <div class="post-nav nav-previous">
        <template v-if="listPageBySlug[post.slug].prev.slug">
          <router-link :to="{name: 'PostDetail', params: {slug: listPageBySlug[post.slug].prev.slug}}">
            <span class="post_nav-text">Previous post</span>
            <span class="nav-post-title" v-html="listPageBySlug[post.slug].prev.title"></span>
          </router-link>
        </template>
      </div>
      <div class="post-nav nav-next">
        <template v-if="listPageBySlug[post.slug].next.slug">
          <router-link :to="{name: 'PostDetail', params: {slug: listPageBySlug[post.slug].next.slug}}">
            <span class="post_nav-text">Next post</span>
            <span class="nav-post-title" v-html="listPageBySlug[post.slug].next.title"></span>
          </router-link>
        </template>
      </div>
    </div>
    <!--  End Next & Previous Posts  -->
  </div>
</template>
<script>
import UtilService from '@/utils/UtilService'
export default {
  data() {
    return {
      isSelect: false,
      isLoading: false,
      post: null,
      nextPost: null,
      prevPost: null,
      categoryLink: [
        { id: 0, link: 'Technology Posts', title: 'Kiến thức công nghệ' },
        { id: 1, link: 'Timebird Posts', title: 'Góc Timebird' },
        { id: 2, link: 'Product Posts', title: 'Chuyên tin sản phẩm' },
      ]
    }
  },
  created() {
    this.$store.dispatch('setIsBlogPage', false)
    this.setData()

    // this.fetch()
  },
  computed: {
    getPostBySlug() {
      return this.$store.getters.getPostBySlug
    },
    listPageBySlug() {
      return this.$store.getters.listPageBySlug
    }
  },
  watch: {
    '$route.params': function() {
        this.setData()
    }
  },
  methods: {
    setData () {
      const params = this.$route.params
      if(this.getPostBySlug && this.getPostBySlug[params.slug]) {
        this.post = this.getPostBySlug[params.slug]
      } else {
        this.fetch()
      }
    },
    fetch() {
      const params = this.$route.params
      if (!params.slug && params.slug !== 0) {
        this.$router.push({name: 'Posts'})
      }
      this.getPost({
        per_page: 1,
        slug: params.slug
      })
    },
    async getPost(data) {
      this.isLoading = true
      await this.$store.dispatch('getPosts', data)
      this.isLoading = false
      this.post = this.getPostBySlug[data.slug]
    },
    convertTimeStamp(time) {
      return UtilService.convertTimeStamp(time, 'LL')
    },
    goPage(id) {
      this.$router.push({
        name: 'PostDetail',
        id: id
      })
    },
  }
}
</script>
