<template>
  <div class="landing-page">
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/views/landing/Header'
import Footer from '@/views/landing/Footer'
export default  {
  data () {
    return {

    }
  },
  components: {
    Header,
    Footer
  }

}
</script>
