<template>
  <div class="posts-list" v-loading="isLoading">
    <template v-if="posts.length">
      <template v-for="(post, index) in listPostByPage[currentPage - 1]">
        <article v-if="post.status" :key="index" class="post" :id="`post-${post.id}`">
          <div v-if="post.url">
            <router-link :to="{name: 'PostDetail', params: {slug: post.slug}}">
              <img :src="post.url" :alt="post.title">
            </router-link>
          </div>
          <div class="post-header" v-else-if="post.image">
            <router-link :to="{name: 'PostDetail', params: {slug: post.slug}}">
              <img :src="post.image" :alt="post.title">
            </router-link>
          </div>
          <div class="post-content">
            <time class="post-date">{{convertTimeStamp(post.createAt)}}</time>
            <h2 class="post-title"><router-link :to="{name: 'PostDetail', params: {slug: post.slug}}" v-html="post.title"></router-link></h2>
            <div class="post-entry" v-html="post.excerpt">
            </div>
            <div class="post-readmore">
              <router-link :to="{name: 'PostDetail', params: {slug: post.slug} }" class="btn">Read More</router-link>
            </div>
          </div>
        </article>
      </template>
    </template>
    <div class="nav-links" v-if="posts.length">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :currentPage="currentPage"
          @current-change="goPage"
          :total="posts.length"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import UtilService from '@/utils/UtilService'
import { chunk } from "lodash"
export default {
  data() {
    return {
      isLoading: false,
      posts: [],
      currentPage: 1,
      perPage: 10
    }
  },
  computed: {
    listPostByPage() {
      return chunk(this.posts, this.perPage)
    },
  },
  created() {
    const query = this.$route.query
    if (query.page) {
      this.currentPage = parseInt(query.page)
    }
    this.$store.dispatch('setIsBlogPage', true)
    this.fetch()
  },
  methods: {
    fetch() {
      if (this.$route.path === "/posts/technology-posts") {
        this.getTechnologyPosts()
      } else if (this.$route.path === "/posts/timebird-posts") {
        this.getTimebirdPosts()
      } else if (this.$route.path === "/posts/product-posts") {
        this.getProductPosts()
      } else {
        this.getPosts()
      }
    },
    getPosts() {
      this.isLoading = true
      this.$store.dispatch('getPosts').then(rs => {
        this.isLoading = false
        if (rs) {
          this.posts = rs
          // this.pagination.totalPosts = parseInt(rs.headers['x-wp-total'])
          // this.pagination.totalPages = parseInt(rs.headers['x-wp-totalpages'])
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    getTechnologyPosts() {
      this.isLoading = true
      this.$store.dispatch('getTechnologyPosts').then(rs => {
        this.isLoading = false
        if (rs) {
          this.posts = rs
          // this.pagination.totalPosts = parseInt(rs.headers['x-wp-total'])
          // this.pagination.totalPages = parseInt(rs.headers['x-wp-totalpages'])
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    getTimebirdPosts() {
      this.isLoading = true
      this.$store.dispatch('getTimebirdPosts').then(rs => {
        this.isLoading = false
        if (rs) {
          this.posts = rs
          // this.pagination.totalPosts = parseInt(rs.headers['x-wp-total'])
          // this.pagination.totalPages = parseInt(rs.headers['x-wp-totalpages'])
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    getProductPosts() {
      this.isLoading = true
      this.$store.dispatch('getProductPosts').then(rs => {
        this.isLoading = false
        if (rs) {
          this.posts = rs
          // this.pagination.totalPosts = parseInt(rs.headers['x-wp-total'])
          // this.pagination.totalPages = parseInt(rs.headers['x-wp-totalpages'])
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    convertTimeStamp(time) {
      return UtilService.convertTimeStamp(time, 'LL')
    },
    async goPage(page) {
      this.currentPage = page
      const query = Object.assign({}, this.$route.query)
      query.page = this.currentPage
      await this.$router.push({ query })
      // this.fetch()
    }
  }
}
</script>
