<template>
  <main>
    <full-page ref="fullpage" :options="options">
      <!-- Header -->
      <section class="page section section-header">
        <div class="section-inner">
          <div class="container">
            <div class="section-left flex-4">
              <div class="title">
                <h2><strong>"Sống"</strong> theo chất Timebird,</h2>
                <h3>Toả sáng theo cách riêng bạn</h3>
              </div>
              <p class="only-desktop">
                <router-link :to="{name: 'Works'}" class="btn">Cơ hội việc làm đang mở</router-link>
              </p>
            </div>
            <div class="section-right flex-3">
              <p>Bắt đầu hoạt động chính thức từ giữa 2020 với nền tảng công nghệ tiên tiến và đội ngũ dày dặn kinh nghiệm hơn 10 năm trong ngành CNTT, TimeBird gắn kết và cam kết mang đến cho khách hàng những giải pháp hiệu quả với công nghệ mới nhất, độ bảo mật dữ liệu lớn.</p>
              <p>Tinh thần dân chủ, cởi mở và không ngừng phát triển luôn luôn được chúng tôi phát huy trong công việc cũng như trong các hoạt động vui chơi, giải trí. Đó chính là động lực, là năng lượng để chúng tôi, TimeBird, lớn mạnh vững chắc mỗi ngày.</p>
              <p class="only-mobile">
                <router-link :to="{name: 'Works'}" class="btn">Cơ hội việc làm đang mở</router-link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- End Header -->

      <!-- Future -->
      <section class="page section section-future">
        <div class="container">
          <div class="section-inner">
            <div class="section-left flex-1">
              <div class="title">
                <h3>Con đường chúng tôi đi</h3>
                <h2>Tương lai của TimeBird</h2>
              </div>
              <p>Dẫn đầu trong giải pháp công nghệ đảm bảo độ chính xác và tính bảo mật cao cho các hoạt động đầu tư, thương mại.</p>
              <p>Cung cấp hệ sinh thái công nghệ cao đáp ứng đa dạng nhu cầu của khách hàng an toàn và nhanh chóng.</p>
            </div>
            <div class="section-right flex-1">
              <img src="/images/home/graphic-about.png" alt="Image">
            </div>
          </div>
        </div>
      </section>
      <!-- End Future -->

      <!-- Quality -->
      <section class="page section section-quality">
        <div class="section-inner">
          <div class="container">
            <div class="section-left flex-2">
              <div class="title">
                <h2>Chất Timebird</h2>
              </div>
              <p class="only-desktop">
                <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
              </p>
            </div>
            <div class="section-right flex-5">
              <p class="highlight">Minh bạch | Bảo mật | Liêm chính</p>
              <p class="highlight">Tôn trọng | Khoan dung | Không khoan nhượng | Vươn lên</p>
              <p>Trong từng dòng code, từng hành động.</p>
              <p>Chúng tôi tin rằng mỗi cá nhân đều có cá tính rất riêng và cách thể hiện khác biệt, nhưng bằng "chất TimeBird" chúng tôi sẽ tạo nên cốt lõi vững chắc, tạo nên nguồn dưỡng chất thiết yếu giúp mỗi cá nhân phát huy tối đa tài năng tiềm ẩn.</p>
              <p class="only-mobile">
                <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- End Quality -->

      <!-- Develop -->
      <section class="page section section-develop">
        <div class="container">
          <div class="section-inner">
            <div class="flex-box">
              <div class="section-left flex-5">
                <div class="title">
                  <h3>Góp nhặt tri thức, chia sẻ kinh nghiệm,</h3>
                  <h2>Cùng nhau phát triển</h2>
                </div>
              </div>
              <div class="section-right flex-4">Người dày dạn kinh nghiệm sẽ có những bài học đáng giá, những tips hay ho khi áp dụng tri thức từ sách vở vào thực tế. Người trẻ có lợi thế nhanh nhẹn, học hỏi và nắm bắt xu thế nhanh. Công nghệ là không có giới hạn và luôn đổi mới từng phút giây.</div>
            </div>
            <div class="flex-box">
              <div class="section-left flex-1">
                <p>
                  <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
                </p>
              </div>
              <div class="section-right flex-3">
                <p>Hiểu rõ điều đó, tại TimeBird luôn có những buổi chia sẻ nội bộ để có thể phát huy được khả năng của mỗi cá nhân đồng thời thúc đẩy xây dựng kho tri thức chung. Tham gia buổi seminar mỗi cá nhân sẽ được gợi mở những điều mới, được xem demo, được phản biện và quan trọng hơn là được nghe, được hiểu những góc nhìn khác nhau của đồng đội.</p>
              </div>
            </div>
            <div class="flex-box">
              <div class="img"><img src="/images/home/team-1.png" alt="Team"></div>
              <div class="img only-desktop"><img src="/images/home/team-2.png" alt="Team"></div>
              <div class="img only-desktop"><img src="/images/home/team-3.png" alt="Team"></div>
              <div class="img only-desktop"><img src="/images/home/team-11.png" alt="Team"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Develop -->

      <!-- Healthy -->
      <section class="page section section-healthy">
        <div class="container">
          <div class="section-inner">
            <div class="flex-box">
              <div class="section-left flex-1">
                <p>Đặc thù của ngành phần mềm là chúng tôi luôn ngồi làm việc trước màn hình máy tính trong khoảng thời gian rất dài. Điều đó gây tác hại không tốt tới sức khỏe như hệ xương sống, tiêu hóa. Ở TimeBird chúng tôi có trang bị bàn bóng bàn tại phòng vui chơi của Công ty. Ngoài bóng bàn, chúng tôi còn thường xuyên tổ chức các trận đá bóng giao hữu với đối tác, khách hàng. Thân thể khỏe mạnh, dẻo dai giúp cho chúng tôi hoạt động nhanh nhẹn, đầu óc linh hoạt, và tận hưởng cuộc sống trọn vẹn hơn.</p>
                <p class="only-mobile">
                  <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
                </p>
              </div>
              <div class="section-right flex-1">
                <div class="title">
                  <h2>Khỏe mạnh thể lực, <br/>sắc bén trí lực</h2>
                </div>
                <p class="only-desktop">
                  <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
                </p>
              </div>
            </div>
            <div class="flex-box">
              <div class="img">
                <img class="only-desktop" src="/images/home/team-5.png" alt="Team">
              </div>
              <div class="img">
                <img class="only-desktop" src="/images/home/team-6.png" alt="Team">
              </div>
              <div class="img">
                <img src="/images/home/team-7.png" alt="Team">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Healthy -->

      <!-- Game -->
      <section class="page section section-game">
        <div class="container">
          <div class="section-inner">
            <div class="flex-box">
              <div class="section-left flex-1">
                <div class="title">
                  <h3>Làm hết sức</h3>
                  <h2>Chơi hết mình</h2>
                </div>
              </div>
              <div class="section-right flex-2">
                <p>Làm việc theo phương pháp Agile Scrum cho chúng tôi sự linh hoạt, chủ động trong công việc. Mỗi cá nhân được trao quyền tự chủ, tự chịu trách nhiệm cao nhất đối với bản thân và đối với toàn team. Họp daily meeting, sprint review meeting, restrospective giúp cho hiện trạng công việc được cập nhập real time và kết quả của chúng tôi được đánh giá minh bạch, dân chủ.</p>
              </div>
            </div>
            <div class="flex-box">
              <div class="section-left flex-1">
                <p class="only-desktop">
                  <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
                </p>
              </div>
              <div class="section-right flex-4">
                <p>Nhờ được chủ động sắp xếp công việc nên mỗi khi có lời hiệu triệu, ngay lập tức tất cả TimeBirder lại hào hứng tham gia. Đi ăn, đi chơi, đi hát karaoke rồi lại đi ăn, đi chơi tới cùng ^^ Rồi sau đó chúng tôi lại cặm cụi hoàn thành công việc còn dang dở theo đúng thời hạn cam kết với khách hàng.</p>
                <p>Chơi là không chờ đợi mà làm thì nhất định sẽ không làng nhàng.</p>
                <p class="only-mobile">
                  <router-link :to="{name: 'Team'}" class="btn">Đọc thêm</router-link>
                </p>
              </div>
            </div>
            <div class="flex-box">
              <div class="img only-desktop"><img src="/images/home/team-8.png" alt="Team"></div>
              <div class="img only-desktop"><img src="/images/home/team-9.png" alt="Team"></div>
              <div class="img"><img src="/images/home/team-10.png" alt="Team"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Game -->

      <!-- Job -->
      <section class="page section section-job">
        <div class="container">
          <div class="section-inner">
            <div class="flex-box">
              <div class="section-left flex-3">
                <div class="title">
                  <h2>Cơ hội việc làm</h2>
                  <h3>Gia nhập cùng Timebird</h3>
                </div>
                <p class="only-desktop">
                  <router-link :to="{name: 'Works'}" class="btn">Xem tất cả</router-link>
                </p>
              </div>
              <div class="section-right flex-4">
                <p>Chúng tôi luôn sẵn sàng chào đón các cá nhân phù hợp với con người và văn hóa TimeBird. Bằng Chất TimeBird, chúng tôi sẽ cùng bạn phát triển.</p>
                <p>Các cơ hội việc làm đang chờ đón Bạn!</p>
                <p class="only-mobile">
                  <router-link :to="{name: 'Works'}" class="btn">Xem tất cả</router-link>
                </p>
              </div>
            </div>
            <div class="flex-box">
              <div class="items">
                <div class="item" v-for="(job, index) in listJobOfHome" :key="index">
                  <router-link :to="{name: 'WorkDetail', params: {slug: job.slug}}">
                    <div class="img">
                      <img :src="job.url" :alt="job.title">
                    </div>
                  </router-link>
                  <div class="item-content">
                    <h4><router-link :to="{name: 'WorkDetail', params: {slug: job.slug}}" v-html="job.title"></router-link></h4>
                    <div class="item-date">Ngày đăng: {{job.time}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Job -->

      <!-- Footer -->
      <Footer class="page"/>
      <!-- End Footer -->
    </full-page>
  </main>
</template>
<script>
import Footer from '@/views/landing/Footer'
import UtilService from '@/utils/UtilService'
export default {
  data() {
    return {
      options: {
        navigation: true,
        licenseKey: 'YOUR_KEY_HEERE',
        responsiveWidth: 1230,
        responsiveHeight: 700,
      },
      jobs: []
    }
  },
  computed: {
    listJobOfHome() {
      return this.$store.getters.listJobOfHome
    },
  },
  created() {
    this.$store.dispatch('getJobs', {
      per_page: 3
    }).then(rs => {
      if (rs) {
        this.jobs = rs
      }
    })
  },
  components: {
    Footer
  },
  methods: {
    convertTime(time) {
      return UtilService.convertTime(time)
    }
  }
}
</script>
