<template>
  <div class="posts-wrapper">
    <div class="posts-header" v-if="isBlogPage">
      <div class="container">
        <div class="header-title">
          <h2>Tin Tức</h2>
        </div>
      </div>
    </div>
    <div class="posts">
      <div class="container">
        <div class="posts-container">
          <PostWidget/>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueStickySidebar from "vue-sticky-sidebar"
import PostWidget from '../components/PostWidget.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    VueStickySidebar,
    PostWidget
  },
  computed: {
    isBlogPage() {
      return this.$store.getters.isBlogPage
    }
  }
}
</script>
