import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import '@/assets/scrolloverflow.min' // Optional. When using scrollOverflow:true
// import '/fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFullPage)

//Language
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// Internationalization
locale.use(lang)
import google from '@/plugin/google'
// Plugin
import RouterInterceptor from '@/plugin/router-interceptor'

Vue.prototype.$google = google

import '@/assets/styles.less'

import {
  Icon,
  Input,
  Select,
  Option,
  Notification,
  MessageBox,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Loading,
  Pagination,
  Tabs,
  TabPane,
} from 'element-ui'

Vue.use(VueClipboard)
Vue.use(Button)
Vue.use(Select)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Option)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading)
Vue.use(Pagination)

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification

Vue.use(require('vue-moment'))
Vue.use(RouterInterceptor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
